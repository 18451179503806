.field {
    flex-grow: 1;
    width: 100%;
    height: 56px;
    border-radius: 4px;
    position: relative;
    transition: 0.3s all;
  }
  .field:hover {
    background-color: rgba(255, 255, 255, 0.45);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  }

  .field.focussed input {
    padding: 24px 16px 8px 16px;
  }
  .field.focussed input + label {
    top: 4px;
    opacity: 1;
    color: #a4c4f4;
  }
  .field.locked {
   pointer-events: none;
  }
  .field input {
    flex-grow: 1;
    width: 100%;
    border: 2px solid #5798fa;
    border-radius: 15px;
    width: 100%;
    height: 56px;
    position: relative;
    padding: 0px 16px;
    font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    background-color: white;
    color: #282828;
    outline: none;
    box-shadow: 0px 4px 20px 0px transparent;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out, 0.1s padding ease-in-out;
    -webkit-appearance: none;
  }
  .field input::-webkit-input-placeholder {
    color: #a4c4f5;
  }
  .field input::-moz-placeholder {
    color: #5798fa;
  }
  .field input:-ms-input-placeholder {
    color: #5798fa;
  }
  .field input:-moz-placeholder {
    color: #5798fa;
  }

  .field input + label {
    position: absolute;
    top: 24px;
    left: 16px;
    font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    color: #0c0b0c;
    opacity: 0;
    pointer-events: none;
    transition: 0.1s all ease-in-out;
  }
  .field input + label.error {
   color: #ec392f;
  }