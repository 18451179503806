body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

span {
  font-weight: 100;
}

.labelstyle {
  color: red;

}

.box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.fixed_header {
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  background-color: #95b0d8;
}

.fixed_body {
  flex-grow: 1;
  overflow: auto;
  min-height: 2em;
}

.fixed_footer {
  flex-shrink: 0;
}

.sunday {
  border: 1px solid white;
  display: flex;
  width: 100%;
  flex-direction: row;
}

.picker {
  height: 27px;
}

.datePickerBlock {
  width: 70%;
}

.goToThisSunday {
  border-radius: 5px;
  border: 2px solid #5798fa;
  background-color: white;
  color: black;
  font-weight: 400;
  margin-left: 10px;
  padding: 1px;
}

.dateAndTopic {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 40%;
}

.dateBlock {
  display: flex;
  width: 20%;
  background-color: #95b0d8;
}

.date {
  flex-grow: 1;
  width: 100%;
  
  border: 2px solid #5798fa;
  border-radius: 15px;
  -moz-border-radius: 15px;
  background-color: white;
}

.datePast {
  padding-top: 13px;
}

.dateFuture {
  padding-top: 39px;
}

.topic {
  border-left: 1px solid white;
  border-right: 1px solid white;
  display: flex;
  flex-direction: column;
  width: 80%;
  background-color: #95b0d8;
}

.speakers {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 60%;
}

.talk {
  display: flex;
  flex-direction: column;
  width: 33%; 
  border: 1px;
  background-color: #95b0d8;
}


.speaker {
  flex-grow: 1;
  height: 100%;
  width:100%;
  background-color: #95b0d8;
  border-radius: 15px;
  -moz-border-radius: 15px;
}

.candidates {
  flex-grow: 1;
  width: 100%;
  line-height: 25px;
}

.speakerName {
   flex-grow: 1;
  width: 85%;
  line-height: 30px;
}

.getList {
  padding-left: 5px;
  padding-right: 5px;
}

@media screen and ( max-width: 900px ) {
  .sunday {
    flex-direction: column;
    padding: 5px;
    background-color: #a1c2f3;
  }

  .dateAndTopic {
    width: 100%;
  }

  .speakers {
    width: 100%;
  }

  .datePast {
    padding-top: 15px;
  }
  
  .dateFuture {
    padding-top: 39px;
  }
}

@media screen and ( max-width: 500px ) {
  .sunday {
    flex-direction: column;
  }

  .dateAndTopic {
    width: 100%;
    flex-direction: column;
  }

  .speakers {
    width: 100%;
    flex-direction: column;
  }

  .dateBlock {
    width: 100%;
  }

  .date {
    background-color: #a1c2f3;
  }

  .topic {
    width: 100%;
  }

  .talk {
    width: 100%;
  }

  .datePast {
    padding: 5px;
  }
  
  .dateFuture {
    padding: 5px;
 }
}