html, body {
  height: 100%;
}


.modal {
  border: 2px solid #5798fa;
  border-radius: 15px;
  position: fixed;
  padding: 20px;
  left: 50%;
  top: 50%;
  width: 350px;
  max-height: 550px;
  transform: translate(-50%, -50%);
  background: white;
}

#closebtn {
    position: relative;
    float: right;
    background: #5798fa;
	  color: white;
    top: -10px;
    right: -10px;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.not_pressed {
  padding: 7px 12px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background-color: #90b9f7;
  border: none;
  border-radius: 15px;
  box-shadow: 0 9px #999;
}

.pressed {
  padding: 7px 12px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  border: none;
  border-radius: 15px;
  background-color: #38609b;
  box-shadow: 0 5px #666;
  transform: translateY(4px)
}

.currentSpeaker {
  padding-bottom: 10px;
}

.theButtons {
  padding-bottom: 15px;
}

@media screen and ( max-width: 500px ) {
  .not_pressed {
    font-size: 10px;
  }

  h4 {
    font-size: 10px;
    margin-bottom: 2px;
  }

  .currentSpeaker {
    padding-bottom: 6px;
  }
  
  .pressed {
    font-size: 12px;
  }

  .modal {
    font-size: 10px;
    padding: 0px;
  }
  
  .modal-main {
    margin: 0px;
    padding: 0px;
  }

  #closebtn {
    top: 2px;
    right: 10px;
}
}